<template>
  <main>
    <transition name="fade">
      <div v-if="loaded">
        <section class="productsSection">
          <div class="container">
            <div>
              <div class="breadWrp">
                <bread-crumbs :items="categoryBc" :last="true"></bread-crumbs>
                <!-- <a href="#" class="back">Назад <span class="decoLine1"></span></a> -->
              </div>
              <div class="sideSectionWrp">
                <catalog-page-filter :d="categories"></catalog-page-filter>
              </div>
              <div class="subscrite">
                <div class="txt">{{ $t("subscribeSoc") }}</div>
                <div class="soc">
                  <template v-if="$store.state.links">
                    <a
                      v-for="(item, index) in $store.state.links"
                      :key="index"
                      :href="item.link"
                      target="_blank"
                    >
                      <span :class="item.icon"></span>
                    </a>
                  </template>
                </div>
              </div>
            </div>
            <div>
              <div class="tileWrp">
                <h1>{{ H1 }}</h1>
              </div>
              <div class="prodGridWrp">
                <news-page-article
                  v-for="item in articles"
                  :key="item.model.id"
                  :d="item"
                ></news-page-article>

                <div class="pagiWrp">
                  <pagination
                    v-if="pagiData.length > 3"
                    :compdata="pagiData"
                    @click="onPagiClick"
                  ></pagination>
                </div>
              </div>
            </div>
          </div>
        </section>

        <four-col-links :d="see_also"></four-col-links>
      </div>
    </transition>
  </main>
</template>

<script>
import CautionModal from "../components/Modals/CautionModal.vue";
import BreadCrumbs from "../components/Parts/BreadCrumbs.vue";
import CatalogPageFilter from "../components/Parts/CatalogPageFilter.vue";
import NewsPageArticle from "../components/Parts/NewsPageArticle.vue";
import Pagination from "../components/Parts/Pagination.vue";
import ProductGridCard from "../components/Parts/ProductGridCard.vue";
import ProductsGrid from "../components/Parts/ProductsGrid.vue";
import CautionSection from "../components/Sections/CautionSection.vue";
import FourColLinks from "../components/Sections/FourColLinks.vue";
export default {
  components: {
    BreadCrumbs,
    ProductsGrid,
    ProductGridCard,
    CautionModal,
    CautionSection,
    NewsPageArticle,
    FourColLinks,
    CatalogPageFilter,
    Pagination
  },
  name: "ProductsShowcase",
  data() {
    return {
      loaded: false,
      articles: null,
      categories: null,
      see_also: null,
      currentPage: null,
      totalPage: null
    };
  },
  watch: {
    currentPage( newVal ) {
      document.title = `${this.$store.state.news_title} - ${this.$t('page')} ${newVal}`;
      document.description = `${this.$store.state.news_description} - ${this.$t('page')} ${newVal}`;
    }
  },
  methods: {
    onPagiClick(p) {
      switch (p) {
        case "prev":
          this.loadNews(this.currentPage - 1);
          break;
        case "next":
          this.loadNews(this.currentPage + 1);
          break;
        default:
          this.loadNews(p);
          break;
      }
      window.scrollTo({
        top: 200,
        behavior: "smooth"
      });
    },
    loadNews(page) {
      let api = "/api/news/all";
      let payload = {
        lang: this.$i18n.locale,
        page: page
      };
      if (this.$route.name == "newscategory") {
        api = "/api/news/get-by-category-slug";
        payload = {
          lang: this.$i18n.locale,
          page: page,
          slug: this.$route.params.category
        };
      }
      let pushObj = { ...this.$route };
      pushObj.query.page = page;
      const resolved = this.$router.resolve(pushObj);
      window.history.pushState(null, null, resolved.href);
      this.axios
        .post(api, payload)
        .then(response => {
          if (this.$store.state.news_title) {
            document.title = this.$store.state.news_title;
            document.description = this.$store.state.news_description;
          }
          this.articles = response.data.data.models;
          this.categories = response.data.data.categories;
          this.see_also = response.data.data.see_also;
          this.currentPage = response.data.data.paginate.current_page;
          this.totalPage = Math.ceil(
            response.data.data.paginate.total /
              response.data.data.paginate.per_page
          );
          // if (this.$route.name == "news") document.title = "Новини";
          // else document.title = this.articles[0].model.category_name;
          this.loaded = true;
        })
        .catch(({ response }) => {
          this.solveStatus(response.status);
        });
    }
  },
  computed: {
    H1() {
      if (this.$route.name == "news") return this.$t("bioNews");
      else return this.articles[0].model.category_name;
    },
    categoryBc() {
      let a = [
        {
          text: this.$t("pages.main"),
          url: {
            name: "MainPage",
            params: {
              locale: this.$i18n.locale == "uk" ? null : this.locale
            }
          }
        },
        {
          text: this.$t("pages.news"),
          url: {
            name: "news",
            params: {
              locale: this.$i18n.locale == "uk" ? null : this.locale
            }
          }
        }
      ];
      if (this.$route.name == "newscategory") {
        a.push({
          text: this.articles[0].model.category_name,
          url: {
            name: "newscategory",
            params: {
              locale: this.locale == "uk" ? null : this.locale,
              category: this.$route.params.category
            }
          }
        });
      }
      return a;
    },
    pagiData() {
      if (this.currentPage) {
        let p = [];
        if (this.currentPage > 1) p.push({ type: "prev", s: true });
        else p.push({ type: "prev", s: false });
        let pre = this.currentPage;
        if (pre > 4) {
          p.push({ type: "page", l: 1 });
          p.push({ type: "dot" });
          for (let i = this.currentPage - 2; i < this.currentPage; i++) {
            p.push({ type: "page", l: i, current: false });
          }
        } else {
          for (let i = 1; i < this.currentPage; i++) {
            p.push({ type: "page", l: i, current: false });
          }
        }
        let post = this.totalPage - this.currentPage;
        if (post > 4) {
          for (let i = this.currentPage; i < this.currentPage + 3; i++) {
            if (i == this.currentPage)
              p.push({ type: "page", l: i, current: true });
            else p.push({ type: "page", l: i, current: false });
          }
          p.push({ type: "dot" });
          p.push({ type: "page", l: this.totalPage });
        } else {
          for (let i = this.currentPage; i <= this.totalPage; i++) {
            if (i == this.currentPage)
              p.push({ type: "page", l: i, current: true });
            else p.push({ type: "page", l: i, current: false });
          }
        }
        if (this.currentPage < this.totalPage)
          p.push({ type: "next", s: true });
        else p.push({ type: "next", s: false });
        return p;
      }
      return null;
    }
  },
  created() {
    let api = "/api/news/all";
    let payload = {
      lang: this.$i18n.locale,
      page: !isNaN(this.$route.query.page) ? this.$route.query.page : 1
    };
    if (this.$route.name == "newscategory") {
      api = "/api/news/get-by-category-slug";
      payload = {
        lang: this.$i18n.locale,
        page: !isNaN(this.$route.query.page) ? this.$route.query.page : 1,
        slug: this.$route.params.category
      };
    }
    this.axios
      .post(api, payload)
      .then(response => {
        if (this.$store.state.news_title) {
          document.title = this.$store.state.news_title;
          document.description = this.$store.state.news_description;
        }
        this.articles = response.data.data.models;
        this.categories = response.data.data.categories;
        this.see_also = response.data.data.see_also;
        this.currentPage = response.data.data.paginate.current_page;
        this.totalPage = Math.ceil(
          response.data.data.paginate.total /
            response.data.data.paginate.per_page
        );
        // if (this.$route.name == "news") document.title = "Новини";
        // else document.title = this.articles[0].model.category_name;
        this.loaded = true;
      })
      .catch(({ response }) => {
        this.solveStatus(response.status);
      });
    document.title = this.$store.state.news_title;
    document.description = this.$store.state.news_description;
  }
};
</script>

<style lang="scss">
.productsSection {
  padding: 30px 0;
  @media (max-width: $ts) {
    padding: 10px 0 30px;
  }
  .container {
    @include container;
    display: grid;
    grid-template-columns: 25% calc(75% - 1px);
    @media (max-width: $tl) {
      grid-template-columns: 1fr;
      // & > div:nth-child(1) {
      // 	order: 1;
      // }
    }
    .breadWrp {
      @media (max-width: $tl) {
        order: 1;
        border-bottom: unset !important;
        display: none;
      }
      @media (max-width: $ts) {
        padding: 10px 0;
      }
      .breadCrumbs {
        @media (max-width: $tl) {
          display: none;
        }
      }
      .back {
        display: none;
        color: $cred1;
        position: relative;
        text-decoration: none;
        padding-bottom: 3px;
        .decoLine1 {
          background-color: $cred1;
        }
        @media (max-width: $ts) {
          display: inline-block;
        }
      }
    }
    .subscrite {
      margin-right: 50px;
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid $cgray6;
      @media (max-width: $tl) {
        display: none;
      }
      .txt {
        @include t4;
        margin-bottom: 16px;
      }
      .soc {
        display: flex;
        a {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 1px solid #7f8192;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          text-decoration: none;
          margin-right: 10px;
          color: $cgraybg1;
          transition: 0.3s ease;
          &:hover {
            background-color: $cgraybg1;
            border-color: $cgraybg1;
            color: $cwhite;
          }
        }
      }
    }
    .breadWrp,
    .sideSectionWrp {
      margin-right: 50px;
      @media (max-width: $tl) {
        margin-right: 0;
      }
    }
    .sideSectionWrp {
      @media (max-width: $tl) {
        order: 4;
        margin-bottom: 16px;
      }
    }
    .breadWrp {
      padding-bottom: 47px;
      border-bottom: 1px solid $cgray6;
    }
    .tileWrp {
      @media (max-width: $tl) {
        order: 2;
      }
      h1 {
        @include h2mob;
        margin: 0 0 15px;
      }
    }
    .prodGridWrp {
      @media (max-width: $tl) {
        order: 3;
      }
    }

    .lnkWrp {
      padding: 15px 0;
      .button2 {
        background-color: #fff;
        border: 1px solid $cgray6;
        &:hover {
          background-color: $cgraybg1;
        }
      }
    }
    .pagiWrp {
      display: flex;
      justify-content: flex-end;
      padding-top: 30px;
    }
  }
}
</style>
