<template>
	<div class="newspageitem">
		<div>
			<div class="date">{{ d.model.public_date }}</div>
			<a :href="d.model.url" target="_blank"
				><img :src="path(d.model.image)" alt=""
			/></a>
		</div>
		<div>
			<div class="tags">
				<a
					v-if="d.model.category"
					:href="d.model.category.url"
					@click.stop.prevent="linkClick(d.model.category.url)"
					>{{ d.model.category.title }}</a
				>
			</div>
			<a class="h2wrp" :href="d.model.url" target="_blank"
				><h2>{{ d.translate.name }}</h2></a
			>
			<div class="description" v-html="d.translate.text"></div>
			<a
				v-if="d.model.file"
				:href="pathFile(d.model.file)"
				target="_blank"
				class="docLink"
				@click.stop.prevent="linkClick(pathFile(d.model.file), true)"
			>
				<span class="label">{{ $t("downloadPdf") }}</span>
				<span class="ic-donwload"></span>
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: "NewsPageArticle",
};
</script>

<style lang="scss" scoped>
.newspageitem {
	display: grid;
	grid-template-columns: 27% 1fr;
	padding: 10px 0 25px;
	border-top: 1px solid $cgray6;
	text-decoration: none;
	@media (max-width: $m) {
		grid-template-columns: 1fr 27%;
	}
	& > div:nth-child(1) {
		@media (max-width: $m) {
			order: 2;
		}
	}
	& > div:nth-child(2) {
		padding-left: 20px;
		@media (max-width: $m) {
			padding-left: 0px;
		}
	}
	.date {
		@include t3l;
		margin-bottom: 10px;
		color: $cgray3;
		@media (max-width: $ts) {
			font-size: 14px;
		}
	}
	img {
		width: 100%;
	}
	.tags {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 130%;
		text-decoration-line: underline;
		color: #7f8192;
		margin-bottom: 10px;
		a {
			color: inherit;
		}
	}
	.h2wrp {
		text-decoration: none;
	}
	h2 {
		@include h6;
		margin: 0 0 10px;
	}
	.description {
		color: #000;
	}
	p {
		@include t3l;
	}
	.docLink {
		margin-top: 10px;
		display: flex;
		align-items: center;
		text-decoration: none;
		.label {
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 130%;
			text-decoration-line: underline;
			color: #e1251b;
		}
		.ic-donwload {
			margin-left: 15px;
			width: 35px;
			height: 35px;
			border-radius: 50%;
			background-color: $cred1;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			color: #fff;
		}
	}
}
</style>