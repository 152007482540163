<template>
	<div class="cautionSection">
		<div class="container">
			<div class="closeWrp"><span class="ic-icclose"></span></div>
			<img
				:src="require(`@/assets/img/Caution1.svg`)"
				alt="123"
				class="desctop"
			/>
			<img
				:src="require(`@/assets/img/Caution1Mob.svg`)"
				alt="123"
				class="mob"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: "CautionSection",
};
</script>

<style lang="scss">
.cautionSection {
	padding: 0 0 30px;
	background-color: $cgray6;
	.container {
		@include container;
		.closeWrp {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding: 14px;
			padding-right: 0;
			.ic-icclose {
				cursor: pointer;
				font-size: 18px;
				color: $cred1;
			}
		}
		img {
			width: 100%;
		}
		.desctop {
			display: block;
			@media (max-width: $ts) {
				display: none;
			}
		}
		.mob {
			display: none;
			@media (max-width: $ts) {
				display: block;
			}
		}
	}
}
</style>