<template>
	<section class="FourColsLinks" :class="theme">
		<div class="container">
			<div class="title">
				<h2>{{ d.title }}</h2>
			</div>
			<div class="right">
				<template v-for="(item, index) in d.list">
					<a
						v-if="item.url || item.link"
						:key="index"
						:href="item.url || item.link"
						class="linkItem"
					>
						{{ item.title }}
						<span class="ic-icarrow-right"></span>
					</a>
					<div v-else :key="index" :to="item.url || item.link" class="linkItem">
						{{ item.title }}
						<span class="ic-icarrow-right"></span>
					</div>
				</template>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "FourColsLinks",
	props: ["theme"],
};
</script>

<style lang="scss">
.FourColsLinks {
	padding: 30px 0;
	.container {
		@include container;

		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}
		.right {
			display: grid;
			grid-template: auto / 1fr 1fr 1fr 1fr;
			grid-gap: 10px;
			margin-top: 25px;
			@media (max-width: $tm) {
				grid-template: auto / 1fr 1fr;
			}
			@media (max-width: $ts) {
				grid-template: auto / 1fr;
			}
			.linkItem {
				position: relative;
				padding: 15px 15px 50px;
				@include t3;
				text-decoration: none;
				background-color: $cgray5;
				&:hover {
					background-color: $cgray7;
				}
				.ic-icarrow-right {
					position: absolute;
					bottom: 15px;
					left: 15px;
					font-size: 24px;
					color: $cred1;
				}
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>